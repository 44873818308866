import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { IVariant, UnleashClient } from "unleash-proxy-client";

export const UnleashToggleService = new InjectionToken<IUnleashToggleService>("__UNLEASH_SERVICE__");

export interface IUnleashToggleService extends UnleashClient {
  getFeature$(feature: string): Observable<boolean>;
  getFeature(featureKey: string): boolean;
  onReadyEvent(): Promise<void>;
  setId(id: string): void;
  getVariant(featureKey: string): IVariant;
  getVariant$(featureKey: string): Observable<IVariant>;
}
